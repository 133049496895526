import { FunctionComponent } from 'react'
import styled, { css, CSSObject } from 'styled-components/macro'
import { Tooltip } from 'antd'
import { FlexColumn, FlexRow, Text } from 'styles/generics'
import { EAppFontFamilies } from 'types/enums/EAppFontFamilies'
import { device } from 'constants/media-query'
import infoIcon from 'assets/icons/info-white-icon.svg'
import { ImageWrapper } from 'shared/components/ImageWrapper'

interface Props {
  customStyle?: CSSObject
  infoText?: string
}

const InfoTooltip: FunctionComponent<Props> = ({ customStyle, infoText }) => {
  const TooltipContent = (
    <FlexColumn>
      <TooltipText fontSize="16px">{infoText}</TooltipText>
    </FlexColumn>
  )

  return (
    <TooltipContainer customStyle={customStyle}>
      <Tooltip
        color="white"
        title={TooltipContent}
        overlayClassName="birthday-info-tooltip"
      >
        <FlexRow gap={10} align="center">
          <ImageWrapper src={infoIcon} alt="info" width="16px" />
        </FlexRow>
      </Tooltip>
    </TooltipContainer>
  )
}

const TooltipContainer = styled.div<{
  customStyle?: CSSObject
}>`
  position: relative;
  cursor: pointer;

  ${({ customStyle }) => customStyle && css(customStyle)}
`

const TooltipText = styled(Text)`
  color: black;
  font-family: ${EAppFontFamilies.ATLAS_REGULAR};

  @media ${device.desktop} {
    font-size: 15px;
  }
`

export { InfoTooltip }
