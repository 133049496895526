const personalAreaTexts = {
  general: {
    activeMemberships: 'מנויים פעילים',
    validTo: 'בתוקף עד',
    addRelationship:
      'הילדים שלך ביקשו כרטיס ליום הולדת? חבר טוב רוצה להצטרף למשפחה?',
    addRelationshipCTA: 'לחץ כאן להוספת משתמש',
  },
  header: {
    welcomeFemale: 'ברוכה הבאה,',
    welcomeMale: 'ברוך הבא,',
    personalAreaDescription: 'האיזור האישי שלך במכבי חיפה',
    ticketCreditCTA: 'למימוש הנקודות בקניית כרטיסים',
    officialStoreCreditCTA: ' למימוש הנקודות בחנות הרשמית',
  },
  mySeason: {
    title: 'העונה שלי',
    emptyStateSeasonTickets:
      'אומנם לא נפתחה מכירה של מנויים חדשים, אבל זו ההזדמנות שלכם להצטרף למשפחת +GREEN וליהנות מהטבות בלעדיות, הנחות וקדימות ברכישת כרטיסים לכל הרגעים הגדולים של העונה הקרובה!',
    emptyStateTitle: 'אוהדים יקרים!',
    buyMembershipCTA: 'לפרטים והצטרפות למועדון',
    seasonalMembership: 'מנוי עונתי',
  },
  myCoupons: {
    title: 'ההטבות שלי',
    couponType: 'סוג ההטבה',
    expirationDate: 'בתוקף עד',
    code: 'קוד',
    storeUseable: 'לשימוש בחנות בלבד',
    greenplusCoupon: 'הטבת רכישה גרין פלוס',
    birthdayCoupon: 'הטבת יום הולדת',
    personalCoupons: 'הטבות אישיות',
    couponValue: 'שווי ההטבה',
    emptyStateCoupons:
      'כרגע אין קופונים זמינים עבורכם, אבל ההטבות לא מאחרות לבוא! המשיכו לעקוב אחרי ההזדמנויות הבאות והטבות מיוחדות שתוכלו לממש בקרוב!',
    emptyStateTitle: 'אוהדים יקרים!',
    birthdayCouponInfo: 'מימוש ההטבה אפשרי רק בחודש יום ההולדת שלך',
    noExpiration: 'ללא תוקף',
  },

  myChildren: {
    title: 'הילדים שלי',
    addAccountCTA: 'הוספת משתמש',
    addAccountComment: '(הוספת משתמש תתבצע באתר הכרטיסים)',
    addAccountCommentMobile: '(דרך אתר הכרטיסים)',
  },
  myFriends: {
    title: 'החברים שלי',
    showMore: 'הצג עוד',
    showLess: 'הצג פחות',
  },
  cardLinks: {
    profileCard: {
      title: 'הפרופיל שלי',
      description:
        'כאן תוכלו לעדכן את הפרטים האישיים שלכם, פרטי התקשרות והגדרות חשבון.',
      btnText: 'עדכן פרופיל',
    },
    purchasesCard: {
      title: 'היסטוריית הרכישות שלי',
      description: 'צפו ברכישות כרטיסים או קניה בחנות הרשמית שביצעתם בעבר.',
      btnText: 'להיסטוריית הרכישות',
    },
  },
}

export { personalAreaTexts }
