export const theme = {
  white: '#fff',
  bgGrayLight: '#CBCBCB',
  opacityWhite: '#FFFFFF66',
  white50: '#FFFFFF80',
  gray: '#ADADAD',
  gray300: '#9C9C9C 📋',
  gray400: '#A9A9A9',
  gray500: '#8C8C8C',
  black: '#000',
  grayText: '#757575',
  bgGray: '#F6F6F6',
  grayBorder: '#D9D9D9',
  bgWhite: '#eef3ff',
  primaryGreen: '#007638',
  mhGreen: '#284D2F',
  primaryBlue: '#7bb1fa',
  lightBlack: 'rgba(0, 0, 0, 0.1)',
  transparent: 'transparent',
  breakpoints: {
    sm: '768px',
  },
  gradientGreen:
    'linear-gradient(146.02deg, #007638 -1.1%, #359A65 50.37%, #015629 96.77%);',
  media: {
    xs: (styles: TemplateStringsArray) =>
      `@media (min-width: 576px) { ${styles} }`,
    sm: (styles: TemplateStringsArray) =>
      `@media (min-width: 768px) { ${styles} }`,
    md: (styles: TemplateStringsArray) =>
      `@media (min-width: 992px) { ${styles} }`,
  },
}
