/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FormEvent, useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../store/store'
import {
  AUTH_USER_TOKEN_KEY,
  expirationTimeInMinutes,
} from '../../../../constants/auth'
import {
  ButtonWrapper,
  ForgotPasswordButton,
  LoginErrorContainer,
  LoginErrorText,
  LoginFormBody,
  LoginFormFooter,
  PasswordInputWrapper,
  SignupLinkButton,
} from './style'
import { EmailInput } from '../../../../shared/components/email-input/EmailInput'
import { Link, useNavigate } from 'react-router-dom'
import { resendConfirmSignUp, signIn } from '../../../../services/AuthService'
import { userLogin } from '../../../../store/reducers/userReducer'
import { ESignupForms } from '../../../../types/enums/ESignupForms'
import { ESignupFormSteps } from '../../../../types/enums/ESignupFormSteps'
import { EAppRoutes } from 'types/enums/EAppRoutes'
import { ErrorTexts, SignupTexts } from '../../../../constants/signup-texts'
import { Loader } from '../../../../shared/components/loader/loader'
import { useIsInAppBrowser } from 'shared/helpers/useIsInAppBrowser'
import { InAppBrowserPopUp } from 'shared/components/InAppBrowserPopUp'
import { cookieService } from 'services/cookie.service'

export const LoginForm: React.FC<any> = ({ setLoginTexts, setFormType }) => {
  const dispatch = useAppDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loginFormData, setLoginFormData] = useState({
    email: '',
    password: '',
  })
  const [errors, setErrors] = useState<{ email?: boolean; password?: boolean }>(
    { email: false, password: false },
  )
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const navigate = useNavigate()
  const [loginError, setLoginError] = useState<boolean>(false)
  const isInAppBrowser = useIsInAppBrowser()
  const [isInAppBrowserModalVisible, setIsInAppBrowserModalVisible] =
    useState(false)

  useEffect(() => {
    setLoginTexts({
      title: SignupTexts.Welcome,
      subtitle: SignupTexts.ToLogInPleaseEnterEmailAddress,
    })
  }, [])

  useEffect(() => {
    if (
      loginFormData.email &&
      loginFormData.password &&
      !errors.email &&
      !errors.password
    ) {
      setIsDisabled(false)
    } else if (!isDisabled) {
      setIsDisabled(true)
    }
  }, [loginFormData])

  const handleEmailChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, email: error })
    setLoginFormData({ ...loginFormData, email: value })
  }

  const handlePasswordChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, password: error })
    setLoginFormData({ ...loginFormData, password: value })
  }

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name)
      })
    })
  }

  const handleSubmit = async (event: FormEvent) => {
    clearCacheData()
    event.preventDefault()

    if (isInAppBrowser) {
      setIsInAppBrowserModalVisible(true)
      return
    }

    setIsLoading(true)
    const user = await signIn(
      loginFormData.email.toLowerCase(),
      loginFormData.password,
    )
    if (user) {
      if ('error' in user || !user.token) {
        await resendConfirmSignUp(loginFormData.email.toLowerCase())
        dispatch(
          userLogin({
            isAuthenticated: false,
            email: loginFormData.email,
            password: loginFormData.password,
          }),
        )
        navigate(EAppRoutes.SIGNUP, {
          state: {
            signupStep: ESignupForms.signupForm,
            signupFormStep: ESignupFormSteps.emailConfirm,
          },
        })
        setIsLoading(false)
        return
      }
      setLoginError(false)
      localStorage.setItem(AUTH_USER_TOKEN_KEY, user.token)
      cookieService.setCookie(
        AUTH_USER_TOKEN_KEY,
        user.token,
        expirationTimeInMinutes,
      )
      dispatch(userLogin(user))
    } else {
      setLoginError(true)
      setIsLoading(false)
    }
  }

  return isLoading ? (
    <Loader></Loader>
  ) : (
    <>
      <InAppBrowserPopUp isModalOpen={isInAppBrowserModalVisible} />
      <LoginFormBody onSubmit={handleSubmit}>
        <EmailInput
          hasError={errors.email}
          errorText={ErrorTexts.EmailIsNotValid}
          callback={handleEmailChange}
        ></EmailInput>
        <PasswordInputWrapper
          hasError={errors.password}
          errorText={ErrorTexts.PasswordIsNotValid}
          callback={handlePasswordChange}
          className="passwordInput"
        ></PasswordInputWrapper>
        <ForgotPasswordButton
          className={`forgotPasswordButton ${loginError ? 'no-margin' : ''}`}
          text={SignupTexts.ForgotPassword}
          callback={() => setFormType('forgotPassword')}
        ></ForgotPasswordButton>
        {loginError && (
          <LoginErrorContainer>
            <LoginErrorText>
              {ErrorTexts.DetailsAreIncorrectOrNotInSystemPleaseCreateANewUser}
            </LoginErrorText>
          </LoginErrorContainer>
        )}
        <ButtonWrapper
          type="submit"
          disabled={isDisabled}
          className={`buttonWrapper ${isDisabled ? 'disabled' : ''}`}
          text={SignupTexts.LogIn}
          callback={handleSubmit}
        />
      </LoginFormBody>
      <LoginFormFooter>
        <SignupLinkButton>
          {SignupTexts.HaventRegisteredYet}
          <Link to={EAppRoutes.SIGNUP}>{SignupTexts.Join}</Link>
        </SignupLinkButton>
      </LoginFormFooter>
    </>
  )
}
