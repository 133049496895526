/* eslint-disable @typescript-eslint/no-explicit-any */
import { IShopifyOrder } from 'types/interfaces/IShopifyOrder'
import { IPersonalDetails } from '../../types/interfaces/IPersonalDetails'
import { ISFUser } from '../../types/interfaces/ISFUser'
import { mhfcAuthApiSlice } from '../apiSlice'
import { utilsService } from 'services/utils.service'
import { ITicket } from 'types/interfaces/ITicket'
import { IGreenplusData } from 'types/interfaces/IGreenplusData'
import { IMembershipData } from 'types/interfaces/IMembershipData'
import { ISFShopifyDiscountCode } from 'types/interfaces/ISFShopifyDiscountCode'

interface IGetSfUserByEmailQuery {
  isExtraDetails?: boolean
  shouldValidateUser?: boolean
}

const urlPrefix = 'user'
export const userApiSlice = mhfcAuthApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSfUserByEmail: builder.query<ISFUser, IGetSfUserByEmailQuery>({
      query: ({ isExtraDetails = false, shouldValidateUser = false }) =>
        `${urlPrefix}?isExtraDetails=${encodeURIComponent(isExtraDetails)}&shouldValidateUser=${encodeURIComponent(shouldValidateUser)}`,
      providesTags: ['User'],
    }),
    createSfUser: builder.mutation<ISFUser, any>({
      query: (body) => ({
        url: urlPrefix,
        method: 'POST',
        body,
      }),
      transformResponse: (results: { data: { user: ISFUser } }) =>
        results.data.user,
    }),
    updateSfUser: builder.mutation<IPersonalDetails, any>({
      query: (body) => ({
        url: urlPrefix,
        method: 'PATCH',
        body,
      }),
      transformResponse: (results: any) => {
        return results?.res?.personalDetails || results.data
      },
      invalidatesTags: ['User'],
    }),
    setUserOnPending: builder.mutation<any, void>({
      query: () => ({
        url: `${urlPrefix}/set-pending`,
        method: 'PATCH',
      }),
      transformResponse: (response: { res: boolean; success: boolean }) =>
        response,

      invalidatesTags: ['User'],
    }),
    deleteSfUserAddress: builder.mutation<{ success: boolean }, any>({
      query: (addressId) => ({
        url: `${urlPrefix}/address/${addressId}`,
        method: 'DELETE',
      }),
      transformResponse: (results: { data: { success: boolean } }) => {
        return results.data
      },
      invalidatesTags: ['User'],
    }),
    getUserShopifyOrders: builder.query<any, void>({
      query: () => ({
        url: `${urlPrefix}/shopify-orders`,
        method: 'GET',
      }),
      transformResponse: (results: {
        shopifyOrders: IShopifyOrder[]
      }): IShopifyOrder[] => {
        return results.shopifyOrders.map((order) => ({
          ...order,
          createdAt: utilsService.isoDateStringToDotSeperatedDateString(
            order.createdAt,
          ),
        }))
      },
    }),
    getUserTickets: builder.query<any, void>({
      query: () => ({
        url: `${urlPrefix}/tickets`,
        method: 'GET',
      }),
      transformResponse: (results: {
        [key: string]: Partial<ITicket>[]
      }): {
        [key: string]: Partial<ITicket>[]
      } => {
        return results
      },
    }),
    getUserMembershipsAndGreenpluses: builder.query<any, void>({
      query: () => ({
        url: `${urlPrefix}/membership-and-greenplus`,
        method: 'GET',
      }),
      transformResponse: (results: {
        [key: string]: Partial<IGreenplusData | IMembershipData>[]
      }): {
        [key: string]: Partial<IGreenplusData | IMembershipData>[]
      } => {
        return results
      },
    }),
    getUserDiscounts: builder.query<
      {
        message: string
        data: ISFShopifyDiscountCode[]
      },
      void
    >({
      query: () => ({
        url: `${urlPrefix}/discounts`,
        method: 'GET',
      }),
      transformResponse: (response: {
        message: string
        data: ISFShopifyDiscountCode[]
      }) => response,
    }),
  }),
  overrideExisting: false,
})
export const {
  useGetSfUserByEmailQuery,
  useCreateSfUserMutation,
  useUpdateSfUserMutation,
  useDeleteSfUserAddressMutation,
  useGetUserShopifyOrdersQuery,
  useGetUserTicketsQuery,
  useGetUserMembershipsAndGreenplusesQuery,
  useSetUserOnPendingMutation,
  useGetUserDiscountsQuery,
  // export a way to invalidate the tag user
} = userApiSlice
