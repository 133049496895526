import { FunctionComponent, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'
import { personalAreaTexts } from 'constants/personal-area-texts'
import { FlexRow, FlexColumn, Heading3, TextRegular } from 'styles/generics'
import { theme } from 'styles/theme'
import copyIcon from 'assets/icons/copyIcon.svg'
import arrowIconOpen from 'assets/icons/arrow-icon-open.svg'
import arrowIconClose from 'assets/icons/arrow-icon-close.svg'
import { ISFShopifyDiscountCode } from 'types/interfaces/ISFShopifyDiscountCode'
import { useAppDispatch } from 'store/store'
import { useIsMobile } from 'shared/helpers/useIsMobile'
import { SectionContainer } from 'pages/personal-area/components/SectionContainer'
import { InfoTooltip } from 'pages/personal-area/components/InfoTooltip'
import { device } from 'constants/media-query'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { errorStatusCodes } from 'constants/errors'
import { useGetUserDiscountsQuery } from 'store/api-slices/userSlice'
import { EAppRoutes } from 'types/enums/EAppRoutes'
import { useNavigate } from 'react-router-dom'
import { setUserCoupons } from 'store/reducers/userReducer'
import { Loader } from 'shared/components/loader/loader'
import { DiscountCodeTypes } from 'types/enums/EDiscountCodeType'

const MyCoupons: FunctionComponent = () => {
  const {
    data: userCouponsData,
    error: userCouponsError,
    isLoading: userCouponsIsLoading,
  } = useGetUserDiscountsQuery()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (
      userCouponsError &&
      (userCouponsError as FetchBaseQueryError).status ===
        errorStatusCodes.NOT_ACCEPTABLE
    ) {
      navigate(EAppRoutes.PENDING_PAGE)
    }

    if (!userCouponsIsLoading && !userCouponsError && userCouponsData?.data) {
      dispatch(setUserCoupons(userCouponsData.data))
    }
  }, [
    userCouponsData,
    userCouponsError,
    userCouponsIsLoading,
    dispatch,
    navigate,
  ])

  const data = userCouponsData?.data ?? []

  const [isEmptyList, birthdayCoupons, greenplusCoupons, personalCoupons] =
    useMemo(() => {
      const deduplicatedMap = new Map<string, ISFShopifyDiscountCode>()

      data.forEach((coupon) => {
        const key = coupon.Name

        if (!key) {
          return
        }

        if (!deduplicatedMap.has(key)) {
          deduplicatedMap.set(key, coupon)
        } else {
          const existingCoupon = deduplicatedMap.get(key)!
          if (!coupon.Is_Active__c && existingCoupon.Is_Active__c) {
            deduplicatedMap.set(key, coupon)
          }
        }
      })

      const deduplicatedData = Array.from(deduplicatedMap.values())

      const birthday = deduplicatedData.filter(
        (coupon) =>
          coupon.Name?.startsWith('BDAY-') ||
          coupon.Name?.startsWith('BDAY-KIDS-'),
      )

      const greenplus = deduplicatedData.filter((coupon) =>
        coupon.Segments__c?.includes('greenplus'),
      )

      const personal = deduplicatedData.filter(
        (coupon) =>
          !coupon.Name?.startsWith('BDAY-') &&
          !coupon.Name?.startsWith('BDAY-KIDS-') &&
          !coupon.Segments__c?.includes('greenplus'),
      )

      return [
        deduplicatedData.length === 0,
        birthday,
        greenplus,
        personal,
      ] as const
    }, [data])

  return (
    <SectionContainer title={personalAreaTexts.myCoupons.title}>
      <HeaderContainer justify="space-between">
        <HeadingTitle>{personalAreaTexts.myCoupons.couponType}</HeadingTitle>
        <HeadingTitle>
          {isEmptyList ? '' : personalAreaTexts.myCoupons.expirationDate}
        </HeadingTitle>
      </HeaderContainer>
      {userCouponsIsLoading ? (
        <Loader
          style={{
            justifySelf: 'center',
            alignSelf: 'center',
            marginTop: '-5px',
          }}
        />
      ) : isEmptyList ? (
        <FlexColumn align="center" gap={10}>
          <EmptyStateTitle>
            {personalAreaTexts.myCoupons.emptyStateTitle}
          </EmptyStateTitle>
          <TextRegular mb="15px" textAlign="center" fontSize="24px">
            {personalAreaTexts.myCoupons.emptyStateCoupons}
          </TextRegular>
        </FlexColumn>
      ) : (
        <ContainerWithGap>
          {greenplusCoupons.length > 0 && (
            <CouponSection
              title={personalAreaTexts.myCoupons.greenplusCoupon}
              coupons={greenplusCoupons}
            />
          )}
          {birthdayCoupons.length > 0 && (
            <CouponSection
              title={personalAreaTexts.myCoupons.birthdayCoupon}
              coupons={birthdayCoupons}
              isInfo
              infoText={personalAreaTexts.myCoupons.birthdayCouponInfo}
            />
          )}
          {personalCoupons.length > 0 && (
            <CouponSection
              title={personalAreaTexts.myCoupons.personalCoupons}
              coupons={personalCoupons}
            />
          )}
        </ContainerWithGap>
      )}
    </SectionContainer>
  )
}

interface CouponSectionProps {
  title: string
  coupons: ISFShopifyDiscountCode[]
  isInfo?: boolean
  infoText?: string
}

const CouponSection: FunctionComponent<CouponSectionProps> = ({
  title,
  coupons,
  isInfo,
  infoText,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const isMobile = useIsMobile()

  const handleCopy = (text: string) => navigator.clipboard.writeText(text)

  const truncateText = (text: string, maxLength: number) =>
    text.length > maxLength ? `${text.slice(0, maxLength)}...` : text

  return (
    <>
      <SectionHeader onClick={() => setIsOpen(!isOpen)}>
        <SectionTitle>
          <ToggleIcon>
            {isOpen ? (
              <img src={arrowIconClose} alt="Collapse Icon" />
            ) : (
              <img src={arrowIconOpen} alt="Expand Icon" />
            )}
          </ToggleIcon>
          {title} {isInfo && <InfoTooltip infoText={infoText} />}
        </SectionTitle>
      </SectionHeader>

      <AnimatedCouponList isOpen={isOpen}>
        {coupons.map((coupon) => (
          <CouponRow key={coupon.Id || coupon.Shopify_Id__c || coupon.Name}>
            <CouponColumn flexValue={isMobile ? 1.4 : 2}>
              {!isMobile && (
                <TextRegular
                  style={{
                    textDecoration: coupon.Is_Active__c
                      ? 'none'
                      : 'line-through',
                  }}
                >
                  {personalAreaTexts.myCoupons.code}
                </TextRegular>
              )}
              <CopyIcon
                src={copyIcon}
                alt="Copy Icon"
                onClick={() => handleCopy(coupon.Name ?? '')}
              />
              <TextRegular
                style={{
                  textDecoration: coupon.Is_Active__c ? 'none' : 'line-through',
                }}
              >
                {truncateText(coupon.Name ?? '', isMobile ? 8 : 19)}
              </TextRegular>
            </CouponColumn>

            <CouponColumn flexValue={1.5}>
              <TextRegular
                style={{
                  textDecoration: coupon.Is_Active__c ? 'none' : 'line-through',
                }}
              >
                {truncateText(
                  personalAreaTexts.myCoupons.storeUseable ?? '',
                  isMobile ? 13 : 19,
                )}
              </TextRegular>
            </CouponColumn>

            {!isMobile && (
              <CouponColumn flexValue={1.5}>
                <TextRegular
                  style={{
                    textDecoration: coupon.Is_Active__c
                      ? 'none'
                      : 'line-through',
                  }}
                >
                  {personalAreaTexts.myCoupons.couponValue}
                </TextRegular>
                <TextRegular
                  style={{
                    textDecoration: coupon.Is_Active__c
                      ? 'none'
                      : 'line-through',
                  }}
                >
                  {`${coupon.Amount__c}${coupon.Type__c === DiscountCodeTypes.PERCENTAGE ? '%' : '₪'}`}
                </TextRegular>
              </CouponColumn>
            )}

            <CouponColumn>
              <TextRegular
                style={{
                  textDecoration: coupon.Is_Active__c ? 'none' : 'line-through',
                }}
              >
                {coupon.Expiration_Date__c
                  ? new Date(coupon.Expiration_Date__c).toLocaleDateString()
                  : personalAreaTexts.myCoupons.noExpiration}
              </TextRegular>
            </CouponColumn>
          </CouponRow>
        ))}
      </AnimatedCouponList>
    </>
  )
}

const AnimatedCouponList = styled(FlexColumn)<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  align-items: center;
  text-align: center;
  width: 100%;
`

const CouponRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  width: 100%;

  /* Apply the border only if it's not the last row */
  &:not(:last-child) {
    border-bottom: 1px solid ${theme.grayBorder};
  }
`

interface CouponColumnProps {
  flexValue?: number
}

const CouponColumn = styled.div<CouponColumnProps>`
  flex: ${({ flexValue }) => flexValue || 1};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 1px;
  text-align: right;

  @media (max-width: 768px) {
    text-align: left;
    gap: 5px;
  }
`

const SectionHeader = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 15px;
  background-color: ${theme.bgGrayLight};
`

const SectionTitle = styled(Heading3)`
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
`

const ToggleIcon = styled.span`
  font-size: 18px;
  margin-right: 5px;
`

const CopyIcon = styled.img`
  cursor: pointer;
  width: 16px;
  height: 16px;
`

const HeaderContainer = styled(FlexRow)`
  padding: 0 15px;
  margin-bottom: 12px;
`

const HeadingTitle = styled(Heading3)`
  font-family: 'Atlas-AAA-Medium';
  font-size: 20px;

  @media ${device.desktop} {
    font-size: 24px;
  }
`

const EmptyStateTitle = styled(Heading3)`
  font-size: 24px;
  text-align: center;
`

const ContainerWithGap = styled(FlexColumn)`
  gap: 3px;
`

export { MyCoupons }
